<template>
  <div class="text-center mt-5" style="margin-bottom:10px!important">
    <h3>Project Checklist</h3>
    <div class="d-flex justify-content-center align-items-center">
      <!-- Doughnut Chart -->
      <div class="doughnut-chart" :style="chartStyle">
        <div class="inner-circle"></div>
      </div>

      <!-- Legend -->
      <div class="ms-4 text-start">
        <div class="legend-item">
          <span class="color-box color-new" style="background-color:#ff5733"></span>
          <span>New: <span>{{ percentages.new }}</span>%</span>
        </div>
        <div class="legend-item">
          <span class="color-box color-in-progress" style="background-color:#ffc300"></span>
          <span>In Progress: <span>{{ percentages.inProgress }}</span>%</span>
        </div>
        <div class="legend-item">
          <span class="color-box color-completed" style="background-color:#28a745"></span>
          <span>Completed: <span>{{ percentages.completed }}</span>%</span>
        </div>
      </div>
    </div>
  </div>
   <div class="container mt-4">
    <!-- Task Boxes -->
    <div 
      v-for="task in tasks" 
      :key="task.id" 
      class="task-box p-3 mb-3 rounded border"
    >
      <!-- Task Title and Status -->
      <div class="d-flex justify-content-between align-items-start">
        <h5 class="mb-2">{{ task.title }}</h5>
        <span 
          class="status-badge px-2 py-1 rounded-pill text-white"
          :class="getStatusClass(task.status)"
        >
          {{ task.status }}
        </span>
      </div>

      <!-- Task Description -->
      <p class="text-muted mb-2">{{ task.desc }}</p>

      <!-- Task Dates -->
      <p class="text-muted mb-0">
        <strong>Start Date:</strong> {{ this.formatDate(new Date(task.startDate)) }} <br>
        <strong>End Date:</strong> {{ this.formatDate(new Date(task.endDate)) }}
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
        tasks:{},
        percentages: {
            new: 0, // Default values before fetching
            inProgress: 0,
            completed: 0,
        },
        apiURL: "https://backend-updateuser-project-553185792079.us-central1.run.app",
    };
  },
  computed: {
    chartStyle() {
      const { new: newPercent, inProgress, completed } = this.percentages;

      // Validate if percentages add up to 100
      const totalPercent = newPercent + inProgress + completed;
      if (totalPercent !== 100) {
        console.error("Percentages must add up to 100!");
        return {};
      }

      // Dynamic conic-gradient for doughnut chart
      return {
        background: `conic-gradient(
          #ff5733 0% ${newPercent}%,
          #ffc300 ${newPercent}% ${newPercent + inProgress}%,
          #28a745 ${newPercent + inProgress}% 100%
        )`,
      };
    },
    
  },
  async mounted() {
    const projectID = this.$route.query.projectID;
    console.log("API URL:", this.apiURL);
    try {
      const response = await axios.get(`${this.apiURL}/getPersonalProject/${projectID}`);
      console.log("Response:", response);

      // Extract data from the API response
      const projectInfos = response.data.message;
      this.tasks = projectInfos.task;

      // Ensure percentages are numbers
      this.percentages.new = Math.round(projectInfos.percentage.new);
      this.percentages.inProgress = Math.round(projectInfos.percentage.inprogress);
      this.percentages.completed = Math.round(projectInfos.percentage.completed);

      console.log("Updated Percentages:", this.percentages);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
  methods:{
    getStatusClass(status) {
      switch (status) {
        case "Completed":
          return "bg-success";
        case "In Progress":
          return "bg-warning text-dark";
        case "New":
          return "bg-danger";
        default:
          return "bg-secondary";
      }
    },
    formatDate(date) {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const day = String(d.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
  }
};
</script>

<style scoped>
:root {
  --new-color: #ff5733;
  --in-progress-color: #ffc300;
  --completed-color: #28a745;
}

.doughnut-chart {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.inner-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 120px;
  background-color: #fff;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.color-box {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 8px;
  border-radius: 3px;
}
.container {
  max-width: 600px;
  margin: 0 auto;
}

.task-box {
  background-color: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.status-badge {
  font-size: 0.9rem;
  font-weight: 500;
}

h5 {
  font-size: 1.2rem;
}

p {
  font-size: 0.95rem;
}
</style>
